export const seo = {
  url: 'carrier',
  title: {
    en: `Carrier's Zone | Partner network`,
    es: 'Zona del Transportista | Red de socios',
    ro: 'Zona transportatorului | Rețeaua de parteneri',
  },
  desc: {
    en: 'Join the network of Omida VLS Partners - one of the largest forwarders in Poland. We offer cooperation for carriers in the field of domestic and international transport.',
    es: 'Únase a la red de Omida VLS Partners, una de las empresas de transporte más grandes de Polonia. Ofrecemos cooperación para transportistas en el campo del transporte nacional e internacional.',
    ro: 'Alăturați-vă rețelei Omida VLS Partners - unul dintre cei mai mari expeditori din Polonia. Oferim cooperare transportatorilor in domeniul transportului intern si international.',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'carrier',
    'partnership',
    'collaboration',
    'cooperation',
  ],
}

export const intro = {
  title: {
    en: `Carrier's Zone`,
    es: 'Zona del Transportista',
    ro: 'Zona Transportatorului',
  },
  desc: {
    en: `
    <span>
      <strong>Billing:</strong></br>
      </br>
      Omida VLS Sp. z o.o.</br>
      AL. GRUNWALDZKA 472C</br>
      80-309 GDAŃSK, POLAND<br/>
      <br/>
      KRS: 0000459017<br/>
      VAT: PL5851466250<br/>
    </span>
    `,
    es: `
    <span>
      <strong>Facturación:</strong></br>
      </br>
      Omida VLS Sp. z o.o.</br>
      AL. GRUNWALDZKA 472C</br>
      80-309 GDAŃSK, POLONIA<br/>
      <br/>
      KRS: 0000459017<br/>
      VAT: PL5851466250<br/>
    </span>
    `,
    ro: `
    <span>
      <strong>Facturare:</strong></br>
      </br>
      Omida VLS Sp. z o.o.</br>
      AL. GRUNWALDZKA 472C</br>
      80-309 GDAŃSK, POLONIA<br/>
      <br/>
      KRS: 0000459017<br/>
      TVA: PL5851466250<br/>
    </span>
    `,
  },
  button: {
    text: {
      en: 'Learn more',
      es: 'Leer más',
      ro: 'Află mai multe',
    },
    action: 'SCROLL',
  },
}

export const main = {
  title: {
    en: 'Carrier Zone - Join the network of Omida VLS Partners',
    es: 'Zona del Transportista - Únase a la red de socios de Omida VLS',
    ro: 'Zona Transportatorului - Alăturați-vă rețelei de parteneri logistici Omida',
  },
  texts: [
    {
      en: '<span>Join the network of Omida VLS Partners - one of the largest forwarding companies in Poland. We offer <strong> cooperation for carriers </strong> in the field of domestic and international transport. Fill out the form below providing your company details and identifying your business needs. Specialists will inform you by e-mail or telephone while preparing a cooperation proposal. </span>',
      es: '<span> Únase a la red de Omida VLS Partners, una de las mayores empresas de transporte de Polonia. Ofrecemos <strong>cooperación para transportistas</strong> en el ámbito del transporte nacional e internacional. Complete el siguiente formulario proporcionando los detalles de su empresa e identificando las necesidades de su negocio. Los especialistas le informarán por correo electrónico o por teléfono mientras preparan una propuesta de cooperación.</span>',
      ro: '<span>Alăturați-vă rețelei Omida VLS Partners - una dintre cele mai mari companii de expediție din Polonia. Oferim <strong>cooperare pentru transportatori </strong> în domeniul transportului intern și internațional. Completați formularul de mai jos furnizând detaliile companiei dvs. și identificând nevoile dvs. de afaceri. Specialiștii vă vor informa prin e-mail sau telefon în timp ce pregătesc o propunere de cooperare. </span>',
    },
  ],
  features: [
    {
      icon: 'przewoznik1',
      subtitle: {
        en: 'Certificate ',
        es: 'Certificado ',
        ro: 'Certificat',
      },
      title: {
        en: 'CWB',
        es: 'CWB',
        ro: 'CWB',
      },
      desc: {
        en: 'Certificate of Business Credibility - Credible Payer',
        es: 'Certificado de Credibilidad Empresarial - Pagador Creíble',
        ro: 'Certificat de credibilitate în afaceri - plătitor credibil',
      },
    },
    {
      icon: 'przewoznik2',
      subtitle: {
        en: 'Fast ',
        es: 'Plazos cortos ',
        ro: 'Rapid',
      },
      title: {
        en: 'Payments',
        es: 'Pagos',
        ro: 'Plăți',
      },
      desc: {
        en: 'Short payment terms on the basis of a discount',
        es: 'Plazos de pago cortos sobre la base de un descuento',
        ro: 'Termene scurte de plată pe baza unei reduceri',
      },
    },
    {
      icon: 'przewoznik6',
      subtitle: {
        en: 'Systematic ',
        es: 'Sistemáticas ',
        ro: 'Sistematic',
      },
      title: {
        en: 'Orders',
        es: 'Órdenes',
        ro: 'Comenzi',
      },
      desc: {
        en: 'Guarantee of continuity of orders and stable cooperation',
        es: 'Garantía de continuidad de pedidos y cooperación estable',
        ro: 'Garanția continuității comenzilor și a cooperării stabile',
      },
    },
    {
      icon: 'przewoznik3',
      subtitle: {
        en: 'Forwarder ',
        es: 'Contacto con el ',
        ro: 'Expeditor ',
      },
      title: {
        en: 'Support',
        es: 'Expedidor',
        ro: 'Susținere',
      },
      desc: {
        en: 'Individual care of the forwarder and the fleet department',
        es: 'Atención individual del expedidor y del departamento de flotas',
        ro: 'Îngrijirea individuală a expeditorului și a departamentului de flote',
      },
    },
    {
      icon: 'przewoznik7',
      subtitle: {
        en: 'Partnership ',
        es: 'Relaciones de ',
        ro: 'Parteneriat ',
      },
      title: {
        en: 'Relations',
        es: 'Asociación',
        ro: 'Relaţii',
      },
      desc: {
        en: 'Openness and equality of cooperation',
        es: 'Apertura e igualdad de la cooperación',
        ro: 'Deschidere și egalitate în cooperare”',
      },
    },
    {
      icon: 'przewoznik4',
      subtitle: {
        en: 'Year-round ',
        es: 'Todo el año ',
        ro: 'Pe tot parcursul anului',
      },
      title: {
        en: 'Cooperation',
        es: 'Cooperación',
        ro: 'Cooperare',
      },
      desc: {
        en: 'Permanent, year-round cooperation in circle routes',
        es: 'Cooperación permanente durante todo el año en círculos.',
        ro: 'Cooperare permanentă, pe tot parcursul anului, în trasee cerc.',
      },
    },
    {
      icon: 'przewoznik8',
      subtitle: {
        en: 'High ',
        es: 'Alto  ',
        ro: 'Înalt ',
      },
      title: {
        en: 'Mileage',
        es: 'Kilometraje',
        ro: 'Kilometraj',
      },
      desc: {
        en: 'Profitable transport orders',
        es: 'Órdenes de transporte rentables',
        ro: 'Comenzi de transport profitabile',
      },
    },
    {
      icon: 'przewoznik5',
      subtitle: {
        en: 'Interesting',
        es: 'Interesantes',
        ro: 'Interesant',
      },
      title: {
        en: 'Bonuses',
        es: 'Bonos',
        ro: 'Bonusuri',
      },
      desc: {
        en: 'Fuel cards, fleet insurance and much more',
        es: 'Tarjetas de carburante, seguros de flota y mucho más',
        ro: 'Carduri de combustibil, asigurare de flotă și multe altele',
      },
    },
  ],
}
